/**
* default reset
*/

@import "./mvp.css";

/**
* additional reset
*/

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
.App-header nav {
  font-family: "Fredoka One", cursive;
}

.card h2,
.App-header nav a {
  letter-spacing: 1ch;
  text-transform: uppercase;
}

.card h2 {
  text-align: center;
}

.fermentables h2 {
  text-shadow: 2px 2px 1px #d91414;
}

.hops h2 {
  text-shadow: 2px 2px 1px #3bd914;
}

.yeast h2 {
  text-shadow: 2px 2px 1px #9637bd;
}

.other h2 {
  text-shadow: 2px 2px 1px #137ec4;
}

table,
textarea {
  width: 100%;
}

textarea {
  min-height: 12em;
}

/**
* Fieldset component
*/

fieldset {
  border: 0;
}

/**
* Card component
*/

.card {
  box-shadow: 1px 1px 2px #505050;
  padding: 0.5em;
  margin: 1em auto;
  width: 100%;
}

.card.error {
  box-shadow: 2px 2px 2px red;
}

/**
* Grid layout
*/

@import "./grid.css";

/**
* Form errors
*/

select.error,
input.error {
  border: 1px solid red;
}

/**
* Accessibility
*/

.screen-reader-text {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  width: 1px;
  word-wrap: normal !important;
}

.screen-reader-text:focus {
  background-color: #f1f1f1;
  border-radius: 3px;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
  clip: auto !important;
  clip-path: none;
  color: #21759b;
  display: block;
  font-size: 0.875rem;
  font-weight: 700;
  height: auto;
  left: 5px;
  line-height: normal;
  padding: 15px 23px 14px;
  text-decoration: none;
  top: 5px;
  width: auto;
  z-index: 100000;
}

/***
*
* Vertical field wrap layout
*/

.vertical-field-wrap {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.vertical-field-wrap label,
.vertical-field-wrap input,
.vertical-field-wrap select {
  margin: 0;
}

/**
* misc overrides
*/

.main-heading {
  margin: 0;
}

.main-heading a {
  display: block;
}

.main-heading img {
  width: 100%;
}

input.error,
select.error {
  border: 1px solid red;
}

nav {
  margin-bottom: 0;
}

.dark-mode {
  border: 0;
  background: transparent;
  color: white;
  position: absolute;
  top: 0;
  right: 20px;
}

.App-header a {
  color: var(--color);
}

.App-header nav a {
  letter-spacing: 1ch;
  text-transform: uppercase;
}

@media (max-width: 900px) {
  .App-header nav {
    flex-direction: column;
  }
  .App-header nav a {
    margin: 1em auto;
  }
  .dark-mode {
    background: purple;
    bottom: 0;
    color: white;
    top: auto;
    right: auto;
  }
}

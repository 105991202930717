@media (min-width: 1200px) {
  .grid {
    display: grid;
    gap: 1em;
  }

  .grid.two-col {
    grid-template-columns: 1fr 1fr;
  }

  .grid.three-col {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .grid > * {
    align-self: stretch;
    box-sizing: border-box;
  }
}

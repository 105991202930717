.success-message {
  font-size: 1.5em;
  text-align: center;
}

.success-message nav {
  flex-direction: column;
  margin: 1em 0;
}

.success-message a {
  color: var(--color);
}
